// Reponsive Media Queries
$small: 568px;
@media screen and (min-width: 1600px){
  .logo-outer {
    height: 7.14vw;
  }
  .logo-header-outer {
    height: 3.5vw;
    margin-top: 1.4vw;
    margin-left: 0.3vw;
    top: 0.7vw;
  }
  .logo-header-inner {
    height: 2.5vw;
    width: 10vw;
  }
  .main-container .largedevice .rwt__tablist[aria-orientation="vertical"]{
    font-size: 1vw !important;
    padding-top: 19px;
    margin-right: 0.7vw !important;
    width: 7.8vw !important;
    padding: 1.3vw 0px 0px 0px !important;
  }
  .main-container .largedevice .rwt__tab {
    padding: 0.5vw 2.2vw !important;
    margin : 0.5vw;
  }
  .main-container .largedevice .rwt__tab[aria-selected='true'] .edit-box {
    background: #fff;
    padding: 0.5vw 0 0.4vw 0;
    border: 0.1vw solid #d00028;
  }
  .main-container .largedevice .text-muted {
    font-size: 2.2vw;
  }
  .main-container .largedevice .rwt__tab[aria-selected='true'] .sidenav {
    color: #d00028;
    min-width: 4vw;
    float: left;
  }
  .main-container .sidenav {
    min-width: 4vw;
    float: left;
    font-size: 0.8vw;
    color: #697582;
    margin-top: 0.7vw;
  }
  .main-container .edit-box{
    padding: 0.5vw 0 0.4vw 0;
  }
  .layers-icon{
    width: 2.1vw;
    height: 2.1vw;
  }
  .footer-outer{
    height: 4.2vw !important;
  }
  .footer-btn,.continue-btn  {
    margin-bottom : 0.5vw !important;
    margin-top : 0.5vw !important;
    padding: 0.4vw 0.8vw;
    font-size: 1.1vw;
  }
  .footer-btn-continue-icon i,.footer-btn-icon i{
    margin-left: 0.3vw;
  }
  .footer-btn-icon i{
    margin-right: 0.3vw;
  }
  .safety-bleed-outer {
    grid-gap: 1.4vw;
    margin-bottom: 1.4vw;
  }
  .canvas-dimension {
    font-size: 0.8vw;
  }
  .bleed-line,.safety-line {
    border: 0.07vw solid #b5babf;
    font-size: 0.8vw;
    border-radius: 0.7vw;
    height: 1.4vw;
    width: 6.4vw;
    padding: 0px 0.8vw 0px;
  }
  .image-gallery, .layers-gallery,.text-gallery,.color-gallery {
    min-height: 32vw;
    max-height: 32vw;
  }
  .color-gallery .popover{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 50%;
  }
  .clip-gallery{
    min-height: 100%;
    max-height: 100%;
  }
  .shape-gallery{
    min-height: 29vw;
    max-height: 29vw;
  }
  .left-side-panel .side-panel-container .img-title {
    margin-bottom: 0.7vw;
  }
  .loader-image-preview.loader-active {
    top: 3.5vw;
  }
  .loader-image-preview {
    padding-left: 1.2vw;
    //grid-gap: 1vw 0.8vw;
  }
  .col-sm-12{
    padding-right: 1vw;
    padding-left: 1vw;
  }
  .gallery-title{
    height: 3.5vw !important;
    font-size: 1vw !important;
  }
  .left-side-panel .side-panel-container .dcl-upload-button {
    margin-bottom: 1vw;
  }
  .left-side-panel .dcl-button {
      border: 0.1vw solid #d00028;
      border-radius: 0.2vw;
      padding: 0.5vw 1.4vw;
      font-size: 1vw;
  }
  .left-side-panel .side-panel-container{
    margin-top: 2.5vw !important;
    width: 17.8vw !important;
    border-radius: 0.2vw;
    box-shadow: 0.07vw 0.07vw 0.07vw 0.07vw rgba(0, 0, 0, 0.4);
  }
  p.img-note,p.img-text {
    font-size: 0.8vw;
  }
  .left-side-panel .side-panel-container .img-upload i {
    font-size: 4.2vw;
  }
  .left-side-panel .side-panel-container .img-title i, .left-side-panel .side-panel-container .layer-title i{
    font-size: 1vw;
  }
  .bottom-outer {
    width: 23vw;
    height: 2.8vw;
    right: 3.5vw;
    margin-top: 1.4vw;
    //bottom: 5vw;
    top: calc(100% - 10vw);
    border: 0.07vw solid #000;
    border-radius: 0.7vw;
  }
  .undoredo button {
    width: 2.8vw;
    height: 2.8vw;
  }
  .btn-undo svg, .btn-redo svg {
    font-size: 1.8vw;
  }
  button#redo{
    margin-right: 0.3vw !important;
  }
  .zoom-range svg {
    width: 1.7vw;
    height: 1.7vw;
    margin-right: 1.4vw;
  }
  .zoom-range span.zoom-value {
    top: 0.2vw;
  }
  .zoom-value {
    font-size: 0.8vw;
    padding: 0.1vw 0.2vw;
    border-radius: 0.2vw;
    margin-right: 1.4vw;
  }
  .clr-btn {
    font-size: 1.1vw;
    width: 5.3vw;
  }
  .sample1-text, .sample2-text, .sample3-text, .sample4-text, .sample5-text{
    margin-bottom: 1vw;
  }
  .sample1-text .sample-text-p1{
    font-size: 1vw;
    margin: 0 0 -0.7vw 0.7vw;
  }
  .sample1-text .sample-text-p2 {
    font-size: 1.5vw;
    margin-bottom: 1.1vw !important;
  }
  .sample1-text .sample1-text-p1 {
    margin-bottom: -1.2vw;
    font-size: 2.1vw;
  }
  .sample1-text .sample1-text-p2 {
    font-size: 1.5vw;
    margin-bottom: -1.5vw !important;
    margin: 0 0 0 1.5vw;
  }
  .sample1-text .sample1-text-p3 {
    font-size: 2.1vw;
  }
  .layer-card {
    margin: 0.7vw 0px 0.7vw 0;
    border: 0.07vw solid transparent;
    box-shadow: 0 0 0.7vw rgba(110, 111, 114, 0.2);
    font-size: 1vw;
    padding: 1vw;
  }
  .layer-shapes {
    grid-column-gap: 0.7vw;
    column-gap: 0.7vw;
  }
  .layer-card img {
    width: 1.7vw;
    height: 1.7vw;
  }
  .layer-shapes i {
    padding: 0.2vw;
  }
  .left-side-panel .side-panel-container .patterns {
    grid-gap: 1.4vw;
  }
  .patterns.shapes-inner {
    border: 0.07vw solid #ccc;
    width: 7.1vw;
    height: 7.1vw;
  }
  .patterns.shapes i {
    font-size: 4.5vw;
  }
  .patterns.shapes-inner .add-shape {
    font-size: 1vw;
    bottom: 0.1vw;
    right: 0.1vw;
    padding: 0.1vw;
  }
  .dcl-upload-button input {
    border: 0.07vw solid #ddd;
    padding: 0.3vw 1.4vw 0.3vw 0.3vw;
    height: 2.5vw;
    font-size: 1vw;
  }
  .dcl-upload-button i {
    height: 2.5vw;
    width: 2.1vw;
    font-size: 1vw;
  }
  .dcl-browse-button {
    margin-bottom: 2.1vw;
  }
  .clipimage-container-outer {
    height: 30vh;
    grid-gap: 0.4vw;
    padding: 0.4vw;
    grid-template-rows: 7.1vw;
  }
  .clipimage-container {
    border: 0.07vw solid #ccc;
  }
  .clipimage-container img {
    height: 7.1vw;
  }
  .clipimage-container-outer .add-clipart {
    right: 0.1vw;
    bottom: 0.1vw;
    padding: 0.1vw;
    font-size: 1vw;
  }
  .color-outer {
    font-size: 0.8vw;
    height: 3.5vw;
    margin-bottom: 0.3vw;
  }
  .color-outer button{
    border: 0.07vw solid rgb(208, 0, 40) !important;
  }
  .left-side-panel .side-panel-container .solid-colors {
    padding: 0.5vw;
  }
  .left-side-panel .side-panel-container .grdcolor, .left-side-panel .side-panel-container .solidcolor {
    width: 2.6vw;
    height: 2.6vw;
    margin: 0 0.5vw 1vw;
    box-shadow: 0 0 0.1vw -0.07px #000;
  }
  span.activeSolidcolor::after {
    border: 0.07vw solid black;
  }
  .sample2-text-p1 {
    font-size: 0.8vw;
  }
  .sample2-text-p2 {
    font-size: 1.4vw;
  }
  .sample3-text-p1 {
    font-size: 1.4vw;
    margin-bottom: -0.7vw !important;
  }
  .sample3-text-p2 {
    font-size: 2.1vw;
  }
  .sample4-text-p1 {
    font-size: 0.8vw;
    margin-top: 0.5vw;
    margin-bottom: -0.5vw;
  }
  .sample4-text-p2 {
    font-size: 1.7vw;
  }
  .sample5-text-p1,.sample5-text-p2,.sample5-text-p3 {
    font-size: 1.7vw;
    margin-bottom: -0.7vw;
  }
  .main-area{
    padding-top: 3.2vw;
  }
  .pre-loader {
    width: 3.5vw;
    height: 3.5vw;
  }
  .spinner-overlay .css-df17o1{
    font-size: 1.1vw;
  }
  .spinner-overlay .css-50etie{
    margin: 0px auto 0.7vw auto;
    width: 3.5vw;
  }
  .toolbar-container {
    padding: 0.7vw 1.4vw;
    height: 4.6vw !important;
  }
  .toolbar-container .colrsec {
    margin-right: 1.4vw;
  }
  .toolbar-container .colrsec svg {
    font-size: 1.4vw;
    margin: 0.2vw;
  }
  .toolbar-container .leftbar p {
    font-size: 0.8vw !important;
  }
  .font-family-dropdown.css-b62m3t-container, .font-family-dropdown.css-3iigni-container {
    min-width: 15.7vw !important;
  }
  .font-family-dropdown .css-13cymwt-control, .font-family-dropdown .css-t3ipsp-control, .font-family-dropdown .css-16xfy0z-control,.font-size-dropdown .css-13cymwt-control {
    min-height: 2vw !important;
    height: 2vw !important;
    border-radius: 0.2vw;
  }
  .font-family-dropdown .css-1wy0on6, .font-size-dropdown .css-1wy0on6{
    max-height: 2vw;
  }
  .font-family-dropdown .css-1fdsijx-ValueContainer, .font-family-dropdown .css-1fdsijx-ValueContainer {
    height: 2vw !important;
  }
  .font-family-dropdown .css-1xc3v61-indicatorContainer, .font-family-dropdown .css-15lsz6c-indicatorContainer, .font-family-dropdown .css-1hb7zxy-IndicatorsContainer {
    padding: 0px 0.5vw !important;
  }
  .toolbar-container .leftbar .font-color-container {
    height: 2vw;
    border: 0.1vw solid #ECECEC;
    border-radius: 0.2vw;
    padding: 0 0.3vw;
    margin-right: 1.4vw;
    margin-bottom: 0.07vw;
  }
  .toolbar-container .leftbar .font-color-container .primcol {
    width: 1.7vw;
    height: 1.1vw !important;
    margin-top: 0.3vw;
    border-radius: 0.1vw;
  }
  .toolbar-container .more-options {
    margin-right: 1.4vw;
    font-size: 1.1vw;
  } 
  .crop-outer {
    grid-gap: 1.4vw;
    gap: 1.4vw;
    margin-bottom: 0.8vw;
  }
  .toolbar-container .rightbar .btn-delete .arrow {
    width: 1vw;
    height: 1.1vw;
    margin: 0.2vw;
    font-size: 1vw;
  }
  .toolbar-container .delete-btn-p {
    font-size: 0.8vw !important;
  }
  .toolbar-container .dropdown-toggle {
    width: 6.4vw;
    font-size: 0.8vw;
    padding: 0 0 1.2vw;
  }
  .dropdown-menu {
    min-width: 11.4vw;
    padding: 0.5vw 0;
    margin: 0.1vw 0 0;
    font-size: 1.1vw;
    border: 0.07vw solid rgba(0,0,0,.15);
    border-radius: 0.2vw;
  }
  .toolbar-container .dropdown-toggle::after {
    margin-left: 0.7vw;
  }
  .dropdown-toggle::after {
    margin-left: 10.4vw;
    vertical-align: 0.2vw;
    border-top: 0.3vw solid;
    border-right: 0.3vw solid transparent;
    border-bottom: 0;
    border-left: 0.3vw solid transparent;
  }
  .dropdown-item {
    padding: 0.2vw 1.7vw;
  }
  .toolbar-container .more-options.colrsec {
    grid-gap: 0 0.8vw;
  }
  .toolbar-container .more-options.colrsec p {
    font-size: 0.8vw;
  }
  .dropdown-menu.show{
    transform: translate(0px, 3vw) !important;
  }
  .font-family-dropdown .css-1dimb5e-singleValue{
    font-size: 1vw;
  }
  .toolbar-container .colrsec svg{
    width: 1.4vw !important;
    height: 1.4vw !important;
  }
  .font-size-dropdown .css-13cymwt-control, .font-size-dropdown .css-t3ipsp-control, .font-size-dropdown .css-16xfy0z-control {
    min-height: 2vw !important;
    max-height: 2vw !important;
    font-size: 1vw;
  }
  .px-text{
    margin: 0px 1.4vw 0px -1.2vw !important;
    font-size: 1vw;
  }
  label{
    font-size: 0.8vw;
  }
  .clipart-dropdown .css-13cymwt-control{
    min-height: 2.7vw;
    font-size: 1vw;
  }
  .clipart-dropdown .css-1u9des2-indicatorSeparator {
    width: 0.07vw;
    margin-bottom: 0.5vw;
    margin-top: 0.5vw;
  }
  .clipart-dropdown .css-1xc3v61-indicatorContainer{
    padding: 0.5vw;
  }
  // .clip-gallery svg{
  //   width: 1.4vw !important;
  //   height: 1.4vw !important;
  // }
  body{
    font-size: 1vw;
  }
  .toolbar-container .rightbar .btn-duplicate {
    margin-right: 1.4vw;
  }
  .crop-text,.tool-text,.toolbar-container .rightbar .btn-duplicate {
    font-size: 0.8vw;
  }
  .crop-done i, .crop-cancel i {
    font-size: 1.7vw;
  }
  .crop-outer svg{
    margin: 0.4vw !important;
  }
  .toolbar-container .leftbar .font-color-container .arrowimg {
    float: right;
    margin-top: 0.5vw;
    width: 0.7vw;
    height: 0.7vw;
  }
  .preview-header{
    font-size: 1.7vw;
    margin-bottom: 1vw;
  }
  .preview-wizard-outer {
    grid-gap: 1.4vw;
  }
  .preview-wizard, .preview-wizard-receipt {
    width: 14.5vw;
    height: 5.3vw;
    margin-bottom: 1vw;
  }
  .preview-wizard::before {
    border-width: 0 0.5vw 0.5vw 0.5vw;
    right: -1.3vw;
    top: 2.3vw;
  }
  #previewModal .modal-header {
    padding: 2.3vw 0.3vw;
    margin-bottom: 1vw;
    border: 0.07vw solid grey;
  }
  .modal-logo{
    width: 100vw !important;
    height: 2.5vw !important;
  }
  #previewModal .modal-body{
    max-width: 100%;
    padding: 0 1.5vw 1.4vw;
  }
  .preview-title-outer h5 {
    font-size: 1.6vw;
  }
  .preview-image {
    grid-gap: 0 3.5vw;
    margin-bottom: 1.7vw;
    border: 0.07vw solid #6d6e71;
    padding: 0.3vw 1vw;
  }
  .preview-img {
    border: 0.1vw solid #cccccc;
    padding: 0.7vw 0;
    margin: 0.7vw 0 0;
  }
  .preview-img::before {
    width: 0.07vw;
    border: 0.07vw dashed #d00028;
    right: -2.1vw;
  }
  .preview-image-inner img {
    width: 28.5vw;
    height: 21.4vw;
  }
  .preview-edit-icon svg {
    width: 1.4vw;
    height: 1.4vw;
  }
  .preview-content {
    margin: 0.7vw 0;
  }
  .preview-content h4 {
    font-size: 1.4vw;
  }
  .preview-qty-price-outer {
    grid-gap: 6vw;
    font-size: 1.5vw;
  }
  .preview-qty, .preview-price {
    grid-gap: 1vw;
  }
  .preview-qty-inner .qty-plus-icon{
    top: 0.9vw;
    left: 0.3vw;
    width: 1vw;
    height: 1vw;
  }
  .preview-qty input {
    width: 5vw;
    padding: 0.7vw;
    height: 2.7vw;
    border: 0.07vw solid #6b6f72 !important;
  }
  .preview-qty-inner .qty-minus-icon {
    right: 0.3vw;
    top: 0.9vw;
    width: 1vw;
    height: 1vw;
  }
  .preview-price p {
    margin: 0.1vw 0;
    font-size: 1.2vw;
  }
  .preview-content button {
    padding: 0.5vw 0.7vw;
    width: 14.2vw;
    font-size: 1vw;
  }
  .preview-content-tips h4 {
    font-size: 1.6vw;
  }
  
  .preview-content-tips p {
    margin-bottom: 1.1vw;
  }
  .clipart-dropdown .css-t3ipsp-control{
    min-height: 2.7vw;
  }
  .font-family-dropdown .css-hlgwow, .font-size-dropdown .css-hlgwow{
    max-height: 2vw;
  }
  .color-picker-inner{
    top: 3.2vw;
  }
  .Toastify{
    .Toastify__toast-container--top-right {
      top: 1.1vw;
      right: 1.1vw;
    }
    .Toastify__toast{
      min-height: 4.5vw;
      margin-bottom: 1.1vw;
      padding: 0.5vw;
      border-radius: 0.3vw;
      max-height: 57vw;
    }
    .Toastify__toast-container{
      padding: 0.2vw;
      width: 22.8vw;
    }
    .Toastify__toast-body{
      padding: 0.4vw;
    }
    .Toastify__close-button > svg{
      height: 1.1vw;
      width: 1vw;
    }
    .Toastify__progress-bar{
      height: 0.3vw;
    }
  }
  .footer-btn-icon svg{
    margin-right: 0.3vw;
    width: 1.4vw;
    height: 1.4vw;
  }
  .preview-qty-inner{
    max-width: 5vw;
  }
  .sketch-picker {
    width: 14vw !important;
    padding: 0.7vw 0.7vw 0px !important;
  }
  .popover{
    left: 0 !important;
    transform: none !important; 
  }
  .custom-color{
    padding: 1.2vw;
  }
  .spinner-border{
    width: 2.2vw;
    height: 2.2vw;
  }
  .loader-image-container{
    width: 7vw;
    height: 6vw;
  }
  .used-image-outer{
    right: 0.3vw;
  }
  .used-image{
    border-radius: 0.5vw;
    font-size: 1vw;
    padding: 0.1vw;
  }
  .uploads-title{
    margin-bottom: 0.8vw;
    font-size: 1.2vw;
  }
  .react-tooltip-lite{
    font-size: 1vw;
    width: 5.5vw;
  }
  .modal-dialog{
    max-width: 36vw;
    margin: 2vw auto;
    font-size: 1vw;
  }
  .modal-content .modal-title span{
    font-size: 1vw !important;
  }
  .modal-header{
    padding: 1.14vw 1.14vw;
  }
  .modal-body{
    padding: 1.14vw;
    p{
      font-size: 1vw !important;
    }
  }
  .modal-footer{
    padding: 0.8vw;
  }
  .modal-footer .btn{
    padding: 0.4vw 0.8vw;
    font-size: 1.14vw;
  }
  .clip-gallery.clipart-spinner-gallery
  {
    min-height: 450px;
    max-height: 450px;
  }
}
@media screen and (min-width: 1100px) {
  .mobilenav {
    display: none !important;

  }
  .mobilePanel {
    display: none !important;
  }
  .largedevice {
    display: block !important;

  }
  .canvas-panel-header-toolbar {
    height: 100px;
  }
  .nav {
    height: 100px
  }
  .largeNavbar {
    min-height: 77px;
  }
}
@media screen and (min-width: 568px) {
  .mobilenav {
    display: none !important;
  }
  .mobilePanel {
    display: none !important;
  }

  .largedevice {
    display: block !important;
  }
}
@media screen and (max-width: 1100px) and (min-width:568px) {
  .nav {
    height: 150px !important;
  }
  .largedevice {
    display: block !important;
  }
  .mobilenav {
    display: none !important;
  }
  .mobilePanel {
    display: none !important;
  }
  .tabletzoom {
    display: block !important;
    position: fixed;
    top: 60%;
    left: 37%;
  }
  .largeNavbar {
    height: 150px;
  }
  .toolbar-container img {
    height: 15px !important;
    width: 15px !important;
  }
  .text-menu {
    font-size: 12px;
  }
}
@media screen and (min-width:$small) and (max-width:799px) {
  .mobilenav {
    display: none !important;
  }
  .largedevice {
    display: block !important;
  }
  .mobilePanel {
    display: none !important;
  }
}
@media screen and (min-width: 991px) and (max-width: 1279px) {
  .temp-canvas {
    margin-left: 25%;
  }
}
@media screen and (max-width: 991px) {
  .logo-outer {
    height: 60px;
  }

  .logo-header-outer {
    top: 0;
    margin-top: 13px;
  }

  .logo-header-inner {
    width: 70px;
  }
  //preview
  .preview-image{
    grid-template-columns: none;
  }
  .preview-image-inner img{
    width: 100%;
  }
  .preview-img::before{
    border: none;
  }
  .preview-content button{
    margin-top: 80px;
  }
  //toolbar
  .font-family-dropdown.css-3iigni-container, .font-family-dropdown.css-b62m3t-container {
    min-width: 140px!important;
  }
  .toolbar-container .dropdown-toggle { 
    width: 70px; 
  }
  .toolbar-container {     
    z-index: 999;
    position: relative;
    top: 8px;
  }
  .dropdown-menu.show {
    display: block;
    max-height: 70vh;
    overflow-x: auto;
  }
}
@media screen and (min-width: 641px) {
  .mobile-side-bar{
    display: none;
  }
}
@media screen and (min-width: 320px) and (max-width: 640px) {
  .mobile-side-bar {
    display: block;
  }
  .mobile-side-bar{
    display: flex;
    width: 100%;
    height: 40px;
    align-self: end;
    float: left;
    position: fixed;
    top: calc(100% - 110px);
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-size: 20px;
  }
  .bottom-outer{
    top: calc(100% - 175px);
  }
  .mobile-side-bar button{
    background-color: #d00028;
    color: #fff;
    border: 0;
    border-radius: 5px;
  }
  .mobilenav.fixed-bottom.mobilenav-active{
    position: fixed;
    top: 61px;
    background-color: #fff;
    height: 100vh;
  }
  .mobile-side-bar svg{
    fill: #d00028;
  }
  .text-muted{
    font-size: 20px;
  }
  .layers-icon{
    width: 20px;
    height: 20px;
  }
  .mobilePanel {
    display: block
  }
  .mobilenav {
    display: block !important;
  }
  .largedevice {
    display: none !important;
  }
  .menu {
    height: 70px;
    background-color: #F1F1F1;
  }
  .menu::-webkit-scrollbar {
    color: aqua;
  }
  .nav-item {
    color: black;
    background-color: #F1F1F1;
  }
  .zoombar {
    bottom: -25%;
  }
  .tabs {
    z-index: auto;
  }
  .menu {
    z-index: 300;
  }
  .selectedColor {
    background-color: #d00028;
    color: white;
  }
  .canvas-panel-header-toolbar {
    min-height: 77px;
  }
  .temp-canvas {
    margin-left: 0;
  }
  .side-nav {
    min-width: 56px;
    float: left;
    font-size: 12px;
    color: #697582;
    margin-top: 10px;
    text-align: center;
  }
  .bottom-outer{
    right: 100px;
  }
  //image gallery
  .mobilenav .img-title{
    background-color: #e5f6fd;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .mobilenav .img-editor{
    background-color: #fff;
    height: 100vh;
    min-width: 100%;
  }
  .mobilenav .img-upload{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mobilenav .img-upload i{
    font-size: 40px;
  }
  .mobilenav p.img-text{
    padding: 18px;
  }
  .mobilenav .loader-image-preview.loader-active{
    top: 200px;
  }
  //text
  .mobilenav .text-editor{
    background-color: #fff;
    height: 100vh;
    min-width: 100%;
    overflow: auto;
  }
  .mobilenav .dcl-upload-button{
    margin-bottom: 10px;
  }
  //color
  .mobilenav .color-gallery{
    min-height: 100vh;
    min-width: 100%;
  }
  .mobilenav .color-title{
    background-color: #e5f6fd;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .mobilenav .solid-colors .solidcolor {
    width: 40px;
    height: 40px;
    display: table;
    border-radius: 50%;
    margin: 5px;
    line-height: 34px;
    float: left;
    box-shadow: 0 0 2px -1px #000;
    text-align: center;
  }
  .mobilenav .solidcolor{
    width: 37px;
    height: 37px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 50%;
    margin: 0 7px 14px;
    box-shadow: 0 0 2px -1px #000;
    text-align: center;
    position: relative;
  }
  .mobilenav .grdcolor{
    width: 37px;
    height: 37px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 50%;
    margin: 0 7px 14px;
    box-shadow: 0 0 2px -1px #000;
    text-align: center;
    position: relative;
  }
  .mobilenav .gradients-colors{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .mobilenav .gradients-colors .grdcol1 {
    background-image: linear-gradient(#62ff00, yellow);
  }

  .mobilenav .gradients-colors .grdcol2 {
    background-image: linear-gradient(to right, red, yellow);
  }

  .mobilenav .gradients-colors.grdcol3 {
    background-image: linear-gradient(-90deg, #39d4cd, #ff9900);
  }

  .mobilenav .gradients-colors .grdcol4 {
    background-image: linear-gradient(to right, #FF1919, #fff);
  }
  .mobilenav .custom-color{
    padding: 16px;
  }
  .mobilenav .solid-colors{
    padding: 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .mobilenav .popover{
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
  //clip
  .mobilenav .clip-gallery{
    background-color: #fff;
    min-height: 100vh !important;
    min-width: 100%;
  }
  .mobilenav .clipimage-container-outer
  {
    height: 40vh !important;
  }
  //shapes
  .mobilenav .shape-gallery{
    background-color: #fff;
    height: 100vh !important;
    min-width: 100%;
  }
  .mobilenav .patterns{
    display: flex;
    flex-wrap: wrap;
    grid-gap: 27px;
    justify-content: center;
  }
  .mobilenav .patterns.shapes-inner{
    position: relative;
    border: 1px solid #ccc;
    width: 100px;
    height: 100px;
    justify-content: center !important;
    align-items: center;
    padding: 0 !important;
    margin: 0;
  }
  //layers
  .mobilenav .layers-gallery{
    background-color: #fff;
    min-height: 100vh !important;
    min-width: 100%;
  }
  .mobilenav .layer-no-content{
    text-align: center;
    position: relative;
    height: 200px;
    padding-top: 0;
    display: flex;
    align-items: end;
    justify-content: center;
  }
  .preview-wizard.preview-wizard-active{
    display: block;
    width: 100%;
  }
  .preview-wizard,
  .preview-wizard-receipt{
    display: none;
  }
  .preview-wizard::before{
    content: none;
  }
  .preview-wizard p{
    margin: 0;
    font-weight: bolder;
    text-align: center;
    height: 100%;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tab-content{
    border: none !important;
  }
  .color-outer button{
    width: 100%;
  }
  .toolbar-container.container {
    margin-top: 0;
  }
  .nav-link{
    padding: 15px 1rem;
  }
  .toolbar-container {
    z-index: 999;
    position: relative;
    top: 0;
    margin: 0;
    width: 100% !important;
    left: 0;
    justify-content: space-between;
    border: 0;
    padding: 11px 10px 0 15px !important;
  }
  .logo-header-inner {
    width: 70px;
    top: 10px;
    left: 10px;
  }
  .logo-header-outer {
    top: 0;
    margin: 0;
  }
  .image.toolbar-container {
    justify-content: center;
  }
  .mobilenav .dcl-button {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    border: 2px solid #d00028;
    border-radius: 3px;
    padding: 8px 20px;
    background-color: #d00028;
    vertical-align: middle;
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  } 
  .mobilenav .dcl-upload-button i{
    right: 16px;
  }
  .mobilenav .shape-row{
    overflow-y: auto;
    height: 64vh;
    padding-bottom: 10px;
  }
  .zoom-value{
    margin-right: 10px;
  }
  .zoom-range svg{
    margin-right: 10px;
  }
  .canvas-dimension{
    width: max-content;
    white-space: nowrap;
  }
  .font-family-dropdown svg{
    width: 15px;
  }
  canvas.lower-canvas {
    touch-action: auto !important;
  }
  .sample1-text, .sample2-text, .sample3-text, .sample4-text, .sample5-text {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    text-align: center;
  }
  .image-container{
    margin: 0 10px 15px 0;
  }
  .image-preview{
    max-height: 50vh;
    overflow-y: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px 15px;
    padding: 0 18px 0 18px;
  }
  .uploads-title{
    grid-column: span 4;
  }
  .image-preview img{
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .image-container{
    height: 0;
    padding-bottom: 100%;
    position: relative;
    width: 100%;
    margin: 0;
  }
  .image-container-inner {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
  }
  .used-image{
    z-index: 9;
    position: relative;
  }
  .loader-image-preview{
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0 18px 0 18px;
  }
  .loader-image-preview{
    top: 230px;
  }
  .loader-image-container{
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
  }
  .spinner-border-outer {
    position: absolute;
    height: auto;
    width: auto;
    top: 50%;
    transform: translateY(-50%);
  }
  .font-family-dropdown .css-olqui2-singleValue{
    width: 50px;
  }
  .font-family-dropdown .css-1cfo1cf{
    margin: 0 !important;
  }
  .font-family-dropdown .css-olqui2-singleValue {
    font-size: 13px;
    width: 46px;
  }
  .scroll-arrows{
    display: flex;
    width: 320px;
    height: 40px;
    align-self: end;
    float: left;
    position: fixed;
    margin-top: 0px;
    bottom: 100px;
    top: calc(100% - 150px);
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  .clip-gallery .dcl-upload-button input{
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 360px){
  .uploads-title {
    grid-column: span 3;
  }
  .image-preview,.loader-image-preview {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 525px)
{
  .color-picker-inner{
    top: 54px !important;
    left: auto !important;
    right: 0;
  }
}
@media screen and (max-width: 480px) {
  .preview-qty-price-outer {
    grid-template-columns: 100%;
    grid-gap: 15px;
  } 
  .css-1dimb5e-singleValue {
    font-size: 13px;
  }
  .toolbar-container .colrsec .font-family-dropdown.css-b62m3t-container .css-1dimb5e-singleValue {
    width: 50px;
  }
  .css-1dimb5e-singleValue {
      font-size: 13px;
  }
  .font-family-dropdown.css-3iigni-container, .font-family-dropdown.css-b62m3t-container {
    min-width: 50px!important;
    width: 101px;
  }
  .toolbar-container .colrsec {
    margin-right: 7px!important;
  }
  .font-family-dropdown .css-1hb7zxy-IndicatorsContainer, .font-family-dropdown .css-1xc3v61-indicatorContainer, .font-family-dropdown .css-15lsz6c-indicatorContainer {
    padding: 0px!important;
  }
  .toolbar-container .dropdown-toggle {
      width: 49px;
  }
  .toolbar-container .leftbar .font-color-container {     
    margin-right: 2px 
  }
  .toolbar-container.container {
      padding: 11px 5px 0 10px !important;
  }
  .image.toolbar-container .colrsec {
    margin-right: 6px!important;
  }
  .image.toolbar-container p.tool-text {
      font-size: 11px;
  }
}
@media screen and (max-width: 425px) {
  .bottom-outer{
    right: 50px;
    width: 300px;
  }
}
@media screen and (max-width: 335px) {
  .clr-btn{
    width: 50px;
  }
  .bottom-outer{
    width: 260px;
  }
}
@media screen and (min-width: 1281px) and (max-width: 1439px) {
  .toolbar-container.toolbar-container-textbox{
    margin-left: 15% !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1281px){
  .toolbar-container.toolbar-container-textbox{
    margin-left: 20% !important;
  }
}
@media screen and (min-width: 641px) {
  .text-gallery{
    min-height: 438px;
    max-height: 438px;
  }
}