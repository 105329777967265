@import "../App.scss";

.left-side-panel {
  transition: width .1s;

  .side-panel-container {
    height: calc(100vh - 100px);
    overflow: hidden;
    width: 250px;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,.4);
    border-radius: 4px;

    .text-editer {
      color: $darkBlueColor;
      background-color: #fff;
    }
    .img-title{
      background-color: #e5f6fd;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }
    .layer-title{
      background-color: #e5f6fd;
      justify-content: space-between;
      align-items: center;
    }
    .layer-no-content{
      text-align: center;
      position: relative;
      height: 200px;
      padding-top: 0;
      display: flex;
      align-items: end;
      justify-content: center;
      @media screen and (min-width : 1600px) {
        height: 14.2vw;
      }
    }
    .img-title i,
    .layer-title i
    {
      cursor: pointer;
    }
    .color-title{
      background-color: #e5f6fd;
      justify-content: space-between;
      align-items: center;
    }
    .img-upload { 
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .img-upload i {
      font-size: 60px;
    }
    .dcl-upload-button{
      margin-bottom: 15px;
      position: relative;
      display: inline-block;
      width: 100%;
    }
    .gradients-colors,
    .patterns {
      display: flex;
      flex-wrap: wrap;
    }
    .patterns{
      grid-gap: 20px;
    }
    .grdcolor,
    .solidcolor {
      width: 37px;
      height: 37px;
      display: flex;
      flex-wrap: wrap;
      border-radius: 50%;
      margin: 0 7px 14px;
      box-shadow: 0 0 2px -1px #000;
      text-align: center;
      position: relative;
    }
    .solid-colors {
      padding: 8px;
      display: flex;
      flex-wrap: wrap;

      .solidcolor {
        cursor: pointer;
      }
    }

    .gradients-colors {
      .grdcolor {
        cursor: pointer;
      }

      .grdcol1 {
        background-image: linear-gradient(#62ff00, yellow);
      }

      .grdcol2 {
        background-image: linear-gradient(to right, red, yellow);
      }

      .grdcol3 {
        background-image: linear-gradient(-90deg, #39d4cd, #ff9900);
      }

      .grdcol4 {
        background-image: linear-gradient(to right, #FF1919, #fff);
      }
    }

    .patterns {
      span {
        height: 92px;
        width: 92px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 5px 10px;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }

  #vertical-tab-four,
  #vertical-tab-one,
  #vertical-tab-two {
    overflow-y: auto;
  }
  
  .dcl-button--full-width {
    display: inline-block;
    width: 100%;
  }

  .dcl-button {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    border: 2px solid #d00028;
    border-radius: 3px;
    padding: 8px 20px;
    background-color: #d00028;
    vertical-align: middle;
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
p.img-text{
  text-align: center;
  color: #697582;
  font-size: 12px;
  padding: 5px;
}
p.img-note{
  text-align: center;
  color: #697582;
  font-size: 12px;
  font-weight: bold;
  width: 100%;
}
.left-side-panel #vertical-tab-four, .left-side-panel #vertical-tab-one, .left-side-panel #vertical-tab-two{
  background: #fff;
}

.color-outer{
    display: inline-flex;
    width: 100%;
    font-size: 12px;
    height: 50px;
    justify-content: space-between;
    background-color: #fff;
    margin-bottom: 5px;
    z-index: 9;
}

.color-outer button{
  border: none;
}

.patterns.shapes i {
  font-size: 4em;
  color: #000;
  cursor: pointer;
}
.patterns.shapes svg {
  font-size: 4em;
  color: #000;
  cursor: pointer;
}
.patterns.shapes img {
  width: 75px;
  height: 70px;
  cursor: pointer;
}

.sample1-text,
.sample2-text,
.sample3-text,
.sample4-text,
.sample5-text {
    display: flex;
    justify-content: space-between;
    margin-bottom : 15px;
    cursor: pointer;
    color : #000;
}

.sample1-text .sample-text-p1{
  font-size: 14px;
  margin-bottom: 0;
  font-family: 'Mukta';
  margin: 0 0 -10px 10px;
  color: #2c2e35;
  text-align: center;
}

.sample1-text .sample-text-p2{
  font-size: 21px;
  color: #ff601f;
  font-weight: 400;
  font-family: "Dancing Script";
  text-align: center;
}

.sample1-text .sample1-text-p1 {
  font-family: 'Abril Fatface';
  margin-bottom: -18px;
  font-size: 30px;
  color: #81DAB8;
}

.sample1-text .sample1-text-p2 {
  font-family: 'Dancing Script';
  font-size: 21px;
  margin-bottom: -22px !important;
  margin: 0 0 0 22px;
  color: #81DAB8;
}

.sample1-text .sample1-text-p3 {
  font-family: 'Abril Fatface';
  margin-bottom: 0;
  font-size: 30px;
  color: #81DAB8;
}

.sample2-text p {
  margin-bottom: 0;
}

.sample2-text-p1 {
  font-size: 12px;
  color: #5895cf;
  font-weight: bold;
  font-family: "Mulish Roman";
  text-align: center;
}

.sample2-text-p2 {
  font-size: 20px;
  color: #173ca0;
  font-weight: 700;
  font-family: "Antonio";
  text-align: center;
}

.sample2-text-p3 {
  font-size: 8px;
  width: 80%;
  font-family: 'Alegreya Sans';
}

.sample3-text-p1{
  font-size: 45px;
  color: #33c1c0;
  font-weight: 400;
  font-family: "Amatic SC";
  text-align: center;
  margin: 0;
}

.sample3-text-p2{
  font-size: 45px;
  color: #d12926;
  font-weight: 400;
  font-family: "Amatic SC";
  text-align: center;
  margin: -20px;
}
.sample3-text-p3{
  font-size: 45px;
  color: #5c6e19;
  font-weight: 400;
  font-family: "Amatic SC";
  text-align: center;
}
.sample4-text-p1{
  font-family: 'Alegreya';
  font-size: 12px;
  margin-top: 8px;
  color: #000;
  margin-bottom: -5px;
}
.sample4-text-p2{
  font-family: 'Aerolite';
  font-size: 24px;
  color: #000;
}

.sample5-text-p1{
  font-family: 'Alegreya';
  font-size: 24px;
  color: red;
  margin-bottom: -10px;
}

.sample5-text-p2{
  font-family: 'Alegreya';
  font-size: 24px;
  color: red;
  margin-bottom: -10px;
}

.sample5-text-p3{
  font-family: 'Alegreya';
  font-size: 24px;
  color: red;
}

.sample6-text-p1{
  font-size: 24px;
  color: #595b61;
  font-weight: 400;
  font-family: "Manrope";
  text-align: center;
  margin: 0;
}
.sample6-text-p2{
  font-size: 24px;
  color: #2c2e35;
  font-weight: 700;
  font-family: "Bebas Neue";
  text-align: center;
  margin: 0;
}
.sample6-text-p3{
  font-size: 24px;
  color: #595b61;
  font-weight: 400;
  font-family: "Manrope";
  text-align: center;
  margin: 0;
}
.sample7-text-p1{
  font-size: 24px;
  color: #708eae;
  font-weight: 700;
  font-family: "Hind";
  text-align: center;
  margin: 0;
}
.sample7-text-p2{
  font-size: 24px;
  color: #11457c;
  font-weight: 700;
  font-family: "Hind";
  text-align: center;
  margin: 0;
}
.sample7-text-p3{
  font-size: 24px;
  color: #11457c;
  font-weight: 700;
  font-family: "Hind";
  text-align: center;
  margin: 0;
}
.sample7-text-p4{
  font-size: 24px;
  color: #11457c;
  font-weight: 700;
  font-family: "Hind";
  text-align: center;
  margin: 0;
}
.sample8-text-p1{
  font-size: 24px;
  color: #6b516b;
  font-weight: 700;
  font-family: "Lato";
  text-align: center;
  margin: 0;
}
.sample8-text-p2{
  font-size: 24px;
  color: #6b516b;
  font-weight: 700;
  font-family: "Lato";
  text-align: center;
  margin: 0;
}
.sample8-text-p3{
  font-size: 24px;
  color: #6b516b;
  font-weight: 700;
  font-family: "Lato";
  text-align: center;
  margin: 0;
}
.sample8-text-p4{
  font-size: 24px;
  color: #422336;
  font-weight: 700;
  font-family: "Lato";
  text-align: center;
  margin: 0;
}
.sample9-text-p1{
  font-size: 24px;
  color: #5d6fba;
  font-weight: 700;
  font-family: "Ubuntu";
  text-align: center;
  margin: 0;
}
.sample9-text-p2{
  font-size: 24px;
  color: #3f487a;
  font-weight: 700;
  font-family: "Ubuntu";
  text-align: center;
  margin: 0;
}
.image-preview {
  position: relative;
  z-index: 9;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-bottom: 10px;
}

.image-preview img{
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: #e9ecef;
  min-height: 7vw;
  max-height: 7vw;
  padding: 0.3vw;
}

.image-upload-gallery-fox{
  width: 100px;
  height: 100px;
  object-fit: contain;
  background: #e9ecef;
}

.image-container {
  position: relative;
  display: inline-block;
  //margin: 0 12px 15px 0
}

.image-container img {
  display: block;
}

.image-container:hover img {
  opacity: 0.5;
  border: 2px solid #d00028;
}

.image-container:hover .icon-outer{
  opacity: 1;
}

.icon-outer{
  opacity: 0;
  position: absolute;
  bottom: 5px;
  padding: 0 5px 0 5px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
}

.used-image{
  background: green;
  border-radius: 7px;
  color: #fff;
  font-size: 13px;
  padding: 2px;
}

.used-image-outer{
  right: 5px;
  position: absolute;
}

.uploads-title{
  //width: 100%;
  float: left;
  margin-bottom: 12px;
  font-size: 17px;
}

.dcl-upload-button i{
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  background: #eaeaea;
  height: 36px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dcl-browse-button select {
  height: 30px;
  width: 100%;
  outline: 0;
  border: 1px solid #ddd;
  cursor: pointer;
}

.dcl-browse-button option {
  cursor: pointer;
}

.dcl-browse-button{
  margin-bottom: 30px;
}

.dcl-img-button img {
  width: 50px;
  height: 50px;
}

.dcl-img-button img {
  object-fit: contain;
  width: 100px;
  margin-right: 10px;
}

.clipimage-container {
    position: relative;
    display: inline-block;
    border: 1px solid #ccc;
}

.clipimage-container img {
  display: block;
  height: 100px;
  object-fit: contain;
  cursor: pointer;
  width: 100%;
}

.dcl-upload-button input{
  border: 1px solid #ddd;
  padding: 5px 20px 5px 6px;
  width: 100%;
  float: left;
  outline: 0;
}

.clipimage-container-outer{
  overflow-y: auto;
  height: 225px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 6px;
  padding: 5px;
  grid-template-rows: 100px;
}

.clipimage-container-outer .add-clipart{
  position: absolute;
  right: 2px;
  bottom: 2px;
  background: #d00028;
  padding: 2px;
  opacity: 0;
  cursor: pointer;
  color: #fff;
}

.clipimage-container-inner{
  border: 0;
}

.clipimage-container:hover{
  border : 1px solid #d00028;
}

.clipimage-container-inner:hover .clipart-img,
.clipimage-container-inner:hover .add-clipart{
  opacity: 0.4;
}

.clipimage-container-inner:hover .add-clipart:hover{
  opacity: 1;
}

.image-gallery,
.layers-gallery,
.clip-gallery{
  min-height: 448px;
  max-height: 448px;
}
.clip-gallery{
  position: relative;
}
.clipart-spinner{
  position: absolute;
  top: 50%;
}

.patterns.shapes-inner{
  position: relative;
  border: 1px solid #ccc;
  width: 100px;
  height: 100px;
  justify-content: center !important;
  align-items: center;
  padding: 0 !important;
  margin : 0;
}

.patterns.shapes-inner:hover{
  border: 1px solid #d00028;
}

.patterns.shapes-inner:hover .shape,
.patterns.shapes-inner:hover .add-shape{
  opacity: 0.4;
}

.patterns.shapes-inner:hover .add-shape:hover{
  opacity: 1;
}

.patterns.shapes-inner .add-shape{
  font-size: 15px;
  position: absolute;
  bottom: 2px;
  right: 2px;
  background: #d00028;
  padding: 2px;
  opacity: 0;
  color: #fff;
}

span.activeSolidcolor::after{
  content: "";
    display: block;
    position: absolute;
    inset: -5px;
    box-sizing: border-box;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 50%;
}

.color-gallery{
  height: 450px;
  background-color: #fff;
}

.custom-color{
  padding: 16px;
}

.layer-card{
  flex-wrap: wrap;
  margin: 10px 0px 10px 0;
  border: 1px solid transparent;
  border-radius: var(--fpd-border-radius);
  box-shadow: 0 0 10px rgba(110,111,114,.2);
  display: flex;
  font-size: 15px;
  overflow: hidden;
  cursor: pointer;
  padding: 15px;
}

.layer-shapes{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
}

.locked{
  opacity: 0.5; 
  cursor: no-drop;
}

.layer-shapes i{
  z-index: 9;
  cursor: pointer;
  padding: 4px;
}

.layer-shapes p{
  margin: 0;
}

.layer-card img{
  width: 25px;
  height: 25px;
  object-fit: contain;
}

.clipart-dropdown .css-13cymwt-control,
.clipart-dropdown .css-13cymwt-control,
.clipart-dropdown .css-t3ipsp-control,
.clipart-dropdown .css-16xfy0z-control{
  cursor: pointer;
}

.loader-image-preview{
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: absolute;
  top: 92px;
  width: 100%;
  gap: 10px;
  margin-bottom: 10px;
}

.loader-image-preview.loader-active{
  top: 50px;
}

.loader-image-container{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 7vw;
  max-height: 7vw;
  padding: 0.3vw;
}

.image-gallery .tab-pane{
  position: relative;
}

// .clip-gallery svg{
//   font-size: 60px;
// }

.clip-gallery-no-image-icon{
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.clip-gallery-no-image-text{
  text-align: center;
}

.clipart-empty{
  position: absolute;
  transform: translateY(-50%);
  right: 0;
  width: 100%;
  text-align: center;
  height: 100%;
  padding-top: 45px;
}
.clipart-spinner{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d00028;
}
.image-gallery .spinner-border{
  color: #d00028;
}
.image-upload-section{
  display: flex;
  gap: 20px;
}
.image-upload-section .spinner-border{
  width: 25px;
  height: 25px;
}