@import "../App.scss";

html {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.main-area {
  width: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: calc(100vh - 100px);
  padding-top: 46px;
}

.canvasfrontback{
  position: relative;
  z-index: 0;
  float: left;
  margin-right: 15px;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(185, 185, 185, 0.3);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
.zoom-value{
  color: #fff;
  font-size: 12px;
  vertical-align: text-top;
  padding: 2px 4px;
  background-color: #4F4F4F;
  border-radius: 4px;
  margin-right: 20px;
}

.bleed-line{
  border: 1px solid #b5babf;
  background: #C4CDD6;
  white-space: nowrap;
  color: #38454f;
  font-size: 12px;
  pointer-events: auto;
  border-radius: 10px;
  height: 20px;
  width: 90px;
  padding: 0px 12px 0px;
  box-shadow: none !important;
}

.bleed-line::after,
.safety-line::after{
  display: none;
}

.bleed-line:hover{
  background: #C4CDD6;
  border: 1px solid #b5babf;
  color: #38454f;
}

.safety-line:hover{
  background: #d00028;
  border: 1px solid #b5babf;
  color: #fff;
}

.btn-success:not(:disabled):not(.disabled).active.bleed-line, .btn-success:not(:disabled):not(.disabled):active.bleed-line, .show>.btn-success.dropdown-toggle.bleed-line{
  background: #C4CDD6;
  border: 1px solid #b5babf;
  color: #38454f;
}

.btn-success:not(:disabled):not(.disabled).active.safety-line, .btn-success:not(:disabled):not(.disabled):active.safety-line, .show>.btn-success.dropdown-toggle.safety-line{
  background: #d00028;
  border: 1px solid #b5babf;
  color: #38454f;
}

.bleed-line.btn-success:focus{
  background: #C4CDD6;
  border: 1px solid #b5babf;
  color: #38454f;
}

.safety-line.btn-success:focus{
  background: #d00028;
  border: 1px solid #b5babf;
  color: #38454f;
}

.bleed-area .dropdown-item,
.safe-area .dropdown-item{
  max-width: 300px;
  white-space: normal;
  text-align: center;
}

.dropdown-item.disabled{
  color: #38454f !important;
  font-size: 12px;
}

.safety-line{
  border: 1px solid #b5babf;
  background: #d00028;
  white-space: nowrap;
  color: #fff;
  font-size: 12px;
  pointer-events: auto;
  border-radius: 10px;
  height: 20px;
  width: 90px;
  padding: 0px 12px 0px;
  box-shadow: none !important;
}

.clr-btn{
  border: none;
  white-space: nowrap;
  color: #000;
  font-size: 16px;
  font-weight: bold;
  pointer-events: auto;
  width: 75px;
  position: relative;
  background : transparent;
}

.undoredo {
  position: relative;
}

.undoredo button {
  width: 40px;
  height: 40px;
  padding: 0;
}

.undoredo button img {
  transform: revert;
  top: 0;
  position: relative;
}

.canvas-dimension{
  font-size: 12px;
}

.safety-bleed-outer{
  display: flex;
  justify-content: center;
  grid-gap: 20px;
  margin-bottom: 20px;
}
.safety-bleed-outer p{
  margin-bottom: 0;
}
.canvas-container{
  border: 1px solid #C4CDD6;
  border-style: dashed;
  margin: 0px 10px;
  background: #fff;
  overflow: hidden;
}

.dropdown-menu{
  width: max-content;
}

.range{
  margin-right: 20px;
}

input[type='range'] {
  border-radius: 8px;
  height: 8px;
  width: 130px;
  outline: none;
  -webkit-appearance: none;
  border : 1px solid #858585;
  transition: 0.1s ease;
}

input[type='range']::-webkit-slider-thumb {
  width: 16px;
  -webkit-appearance: none;
  height: 16px;
  background: #d00028;
  border-radius: 100%;
}

input[type="range"]::-moz-range-thumb {
  background: #d00028;
  border: none;
}

.bottom-outer{
  display: flex;
  width: 320px;
  height: 40px;
  align-self: end;
  float: left;
  position: fixed;
  right: 50px;
  margin-top: 20px;
  //bottom: 100px;
  top: calc(100% - 160px);
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  border-radius: 10px;
}

.zoom-range{
  top: 7px !important
}

.zoom-range span.zoom-value {
  position: relative;
  width: 45px;
  display: inline-block;
  text-align: center;
  @media screen and (min-width: 1600px){
    width: 3.2vw;
  }
}

.zoom-range svg{
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-right: 20px;
}

.react-tooltip-lite{
  background : #000;
  font-size: 10px;
  color: #fff;
}

.btn-outline-secondary{
  border-color: transparent !important;
}

.btn-outline-secondary:hover{
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active{
  color: transparent;
  background-color: transparent;
}

.btn-outline-secondary:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus{
  box-shadow: none;
}

.clr-btn:disabled{
  color: rgb(204, 204, 204);
  cursor: no-drop !important;
}
.canvas-container{
  margin: 0px auto;
}
.canvas-spinner .spinner-border{
  position: absolute;
  left: 50%;
  top: 50%;
  color: #d00028;
}